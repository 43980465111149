import { api } from '@api'
import { Toast } from 'vant'

/**
 * 获取票据
 * @returns
 */
const ZWJSBridge = window.ZWJSBridge //记得declare一下

const getTicket = async () => {
  console.info('获取票据');
  let ticket = '';

  if (ZWJSBridge.ssoTicket) {
    const ssoFlag = await ZWJSBridge.ssoTicket({});
    if (ssoFlag && ssoFlag.result === true) {
      // 使用 浙里办统一单点登录组件
      console.info('使用 浙里办统一单点登录组件');
      if (ssoFlag.ticketId) {
        console.info('应用方服务端单点登录接口');
        ticket = ssoFlag.ticketId;
        return ticket;
      } else {
        console.warn(
          '当浙里办单点登录失败或登录态失效时调用 ZWJSBridge.openLink 方法重新获取 ticketId',
        );
        ZWJSBridge.openLink({
          type: 'reload',
        }).then((res) => {
          ticket = res.ticketId;
          return res.ticket;
        });
      }
    } else {
      console.error(
        '异常情况：当前环境不支持浙里办统一单点登录',
      );

      Toast({ content: '业务异常，请稍后再试' });
      // // 异常情况：当前环境不支持浙里办统一单点登录
    }
  } else {
    console.error(
      '异常情况：ZWJSBridge 加载异常',
    );
  }
  return ticket;
};
// ...
const ssoLogin = async () => {
  const ticketId = await getTicket()
  console.log(ticketId)
  return  api.singleSignOn({
    ticketId,
    type: 1,
  })
};

export default ssoLogin;

