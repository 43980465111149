<!--
 * @Date: 2023-11-20 16:58:16
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-08-16 16:27:04
 * @FilePath: \zyt-mobile-frontend\src\App.vue
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
import ssoTicket from '@/utils/ssoTicket'
import common from '@/utils/common'
import { Dialog, Toast } from 'vant'

export default {
  data() {
    return {
    }
  },
  watch: {
    $route(to, from) {
      ZWJSBridge.onReady(() => {
        console.log('标题修改')
        ZWJSBridge.setTitle({
          title: to.name ? to.name : '浙影通',
        })
      })
    },
  },
  async mounted() {
    await this.initializeApp();
  },
  methods: {
    async initializeApp() {
      try {
        const res = await ssoTicket();
        console.log('获取ssoTicket成功', res);
        const { code, result, message } = res;
        if (code === 200) {
          console.log('个人token成功' + JSON.stringify(result));
          // Toast('个人token成功' + JSON.stringify(result));
          this.$store.commit('changeToken', result.token);
          this.$store.commit('changeZlbUserId', result.userid);
          this.$store.commit('changeUserType', result.userType);
          this.$store.commit('changeUserName', result.username);
          this.$store.commit('changeUserInfo', result);
          Toast.clear();
        } else {
          // console.log('个人token失败' + message);
          Dialog.alert({
            message: '认证失败，请查看登陆状态',
            title: '提示',
            confirmButtonText: '确定',
          });
        }
      } catch (error) {
        console.error('ssoTicket获取失败', error);
        // common.DialogStr('ssoTicket获取失败' + error);
      }
    }
  },
}
</script>
<style lang="less">
#app {
  font-family: Roboto, 'PingFang SC';
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background: #f6f7f9;
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
</style>
