import Vue from "vue"
import VueRouter from "vue-router"
import ruralFilmRoutes from "./ruralFilm"
Vue.use(VueRouter)
import rules from "./routerRules"
const routes = [
  ...ruralFilmRoutes,
  // {
  //   path: '/login',
  //   redirect: '/login'
  // },
  {
    path: "/login",
    name: "模拟登录",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/Login"),
  },
  {
    path: "/auth",
    name: "浙影通-认证",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/auth"),
  },
  // 首页
  {
    path: "/",
    name: "浙影通",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/home/index"),
  },
  // 消息
  {
    path: "/msg",
    name: "消息",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/msg/index"),
  },
  // 我的观影日历
  {
    path: "/calendar",
    name: "观影日历",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/calendar/index"),
  },
  // 我的
  {
    path: "/me",
    name: "我的",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/me/index"),
  },

  // 我的-点播详情
  {
    path: "/playRecord",
    name: "点播记录",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/me/playRecord/index"),
  },
  // 我的-意见反馈
  {
    path: "/feedBack",
    name: "意见反馈",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/me/feedBack/index"),
  },

  // 办事大厅
  {
    path: "/work",
    name: "办事大厅",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/workHall/index"),
  },
  // 办事大厅-信息公开
  {
    path: "/info",
    name: "信息公开",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/infoPublic/index"),
  },
  // 办事大厅-信息公开-影视企业详情
  {
    path: "/companyDetail",
    name: "影视企业详情",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/infoPublic/modules/companyDetail"),
  },
  // 办事大厅-信息公开-电影备案立项详情
  {
    path: "/recordDetail",
    name: "电影备案立项详情",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/infoPublic/modules/recordDetail"),
  },
  // 办事大厅-信息公开-电影公映许可证详情
  {
    path: "/permissionDetail",
    name: "电影公映许可证详情",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/infoPublic/modules/permissionDetail"),
  },
  //影院管理
  {
    path: "/filmAssistant",
    name: "影院管理中心",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
      // keepAlive:true
    },
    component: () => import("@/views/cinemaAssistant/index"),
  },
  //影院详细信息
  {
    path: "/filmAssistant/info",
    name: "影院详细信息",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/cinemaAssistant/cinemaInfo"),
  },
  //预警信息
  {
    path: "/filmAssistant/warningMsg",
    name: "影院详细信息",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/cinemaAssistant/warningMsg"),
  },
  // 政策汇编
  {
    path: "/cinemaAssistant/policyCompilation",
    name: "政策汇编",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/cinemaAssistant/policyCompilation"),
  },
  //排片参考
  {
    path: "/cinemaAssistant/filmArrangement",
    name: "政策汇编",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/cinemaAssistant/filmArrangementReference"),
  },
  //排片参考明细
  {
    path: "/cinemaAssistant/filmArrangementDetail",
    name: "政策汇编",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/cinemaAssistant/filmArrangeDetail"),
  },
  {
    path: "/cinemaAssistant/workProgress",
    name: "办事进度",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () => import("@/views/cinemaAssistant/workProgress.vue"),
  },
  //影院画像
  {
    path: "/cinemaPortrait/yearBoxOffice",
    name: "本年度票房",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/CinemaPortrait/yearBoxOffice.vue"),
  },
  {
    path: "/cinemaPortrait/CinemaRank",
    name: "所在城市票房排行",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/CinemaPortrait/cinemaRank.vue"),
  },
  {
    path: "/cinemaPortrait/fireSafety",
    name: "消防安全等级",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/CinemaPortrait/fireSafety.vue"),
  },
  {
    path: "/cinemaPortrait/averageFare",
    name: "影院平均票价",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/CinemaPortrait/averageFare.vue"),
  },
  {
    path: "/cinemaPortrait/averagePerson",
    name: "影院场均人次",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/CinemaPortrait/averagePerson.vue"),
  },
  {
    path: "/cinemaPortrait/oneScreenProduct",
    name: "影院场均人次",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/CinemaPortrait/oneScreenProduct.vue"),
  },
  // 竞对分析- 票房分析
  {
    path: "/cinemaAssistant/competitorCinema/boxOffice",
    name: "票房分析",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/competitorCinema/boxOffice.vue"),
  },
  // 竞对分析- 票房分析-影片详情
  {
    path: "/cinemaAssistant/competitorCinema/boxOfficeDetail",
    name: "影片详情",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/competitorCinema/boxOfficeDetail.vue"),
  },
  // 上座率分析
  {
    path: "/cinemaAssistant/competitorCinema/attendance",
    name: "上座率分析",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/competitorCinema/attendance.vue"),
  },
  // 上座率分析
  {
    path: "/cinemaAssistant/competitorCinema/attendance/detail",
    name: "上座率分析-详情",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/competitorCinema/attendanceDetail.vue"),
  },
  //排片场次分析
  {
    path: "/cinemaAssistant/competitorCinema/session",
    name: "排片场次分析",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/competitorCinema/session.vue"),
  },
  //排片场次分析
  {
    path: "/cinemaAssistant/competitorCinema/session/detail",
    name: "排片场次分析-详情",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/competitorCinema/sessionDetail.vue"),
  },
  // 排片效益分析
  {
    path: "/cinemaAssistant/competitorCinema/benefit",
    name: "排片效益分析",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/competitorCinema/benefit.vue"),
  },
  // 排片效益分析
  {
    path: "/cinemaAssistant/competitorCinema/benefit/detail",
    name: "排片效益分析-详情",
    meta: {
      rules: [
        rules.business,
        rules.personal,
        rules.group,
        rules.screen,
        rules.super,
      ],
    },
    component: () =>
      import("@/views/cinemaAssistant/competitorCinema/benefitDetail.vue"),
  },
  // draw

  {
    path: "/draw",
    name: "抽奖活动",
    component: () => import("@/views/test/draw.vue"),
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
